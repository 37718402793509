import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'

export default function NotFound() {
  return (
    <Layout title="Not Found">
      <Header title={'Not Found'} sub={'You just hit a route that doesn\'t exist...'}/>
      <div style={{height: '200px'}}/>
    </Layout>
  )
}
